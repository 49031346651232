import React from 'react'
import Dashboard from './Dashboard'


// Subscribe comp 
function Subscribe() {
  return (
    <div>
        <Dashboard/>
    </div>
  )
}

export default Subscribe
