import React from 'react'

function Learnmore() {
  return (
    <div>
        <h1>Learn more comp</h1>
        
    </div>
  )
}

export default Learnmore
