import React from 'react'
import Efficient from './Efficient'
function payment() {
  return (
    <div className='min-h-screen w-screen flex items-center justify-center'>
        <Efficient/>
    </div>
  )
}

export default payment
